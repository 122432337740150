import { Page, Text, Document, StyleSheet, PDFViewer, View, Image } from '@react-pdf/renderer';
import Header from './components/Header';
import { useContext, useEffect, useState } from 'react';
import { ZFWEReportPreferencesContext } from '../../../../../../../../context/ZFWEReportPreferencesContext';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getOrganizationImage } from '../../../../../../functions/getOrganizationImage';
import ThankYou from './components/ThankYou';
import IntroText from './components/IntroText';
import CarbonSequestered from './components/stats/CarbonSequestered';
import OrganicFertilizerCreated from './components/stats/OrganicFertilizerCreated';
import SmartphonesCharged from './components/stats/SmartphonesCharged';
import MilesDriven from './components/stats/MilesDriven';
import TreesPlanted from './components/stats/TreesPlanted';
import HomesPowered from './components/stats/HomesPowered';
import FarmlandRevitalized from './components/stats/FarmlandRevitalized';
import CornGrownFromFarmland from './components/stats/CornGrownFromFarmland';
import MovieTheaterPopcorn from './components/stats/MovieTheaterPopcorn';

// Define styles
const styles = StyleSheet.create({
	body: {
		paddingTop: 25,
		// paddingBottom: 65,
		paddingHorizontal: 35,
		fontFamily: 'Helvetica',
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
		fontWeight: '900',
	},

	logo: {
		width: '30%',
		// border: '1px solid black',
	},
	mainSection: {
		marginTop: 20,
		display: 'flex',
		// border: '1px solid black',
		borderRadius: '10px',
	},

	stats: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		// marginTop: 20,
		margin: 10,
		gap: 10,
	},
});

const PDFSection = () => {
	const {
		event,
		image,
		introText,
		outroText,
		reportFontColor,
		reportHeaderBackground,
		reportHeaderText,
		reportStatisticText,
		FWperCover,
	} = useContext(ZFWEReportPreferencesContext);

	const { organization } = useContext(OrganizationContext);

	const [orgLogo, setOrgLogo] = useState(null);

	const totalAvoidedFoodWaste = (FWperCover / 16) * event.finCoverCount;

	useEffect(() => {
		async function fetchData() {
			try {
				if (organization.logo) {
					const base64Image = await getOrganizationImage(organization.logo);
					const extension = organization.logo.split('.').pop().toLowerCase();
					const mimeType = extension === 'png' ? 'image/png' : 'image/jpeg';
					setOrgLogo(`data:${mimeType};base64,${base64Image}`);
				} else {
					console.log('No logo found');
				}
			} catch (error) {
				console.error('Error fetching org logo:', error);
			}
		}
	
		fetchData();
	}, [organization]);

	return (
		<PDFViewer style={{ width: '100%', height: '100vh' }}>
			<Document style={{ width: '100%', height: '100vh' }}>
				<Page style={styles.body}>
					<Header
						event={event}
						image={image}
						orgLogo={orgLogo}
						headerTextColor={reportHeaderText}
					/>

					<View style={styles.mainSection}>
						<ThankYou
							headerBackgroundColor={reportHeaderBackground}
							headerTextColor={reportHeaderText}
						/>
						<IntroText key={introText} fontColor={reportFontColor} introText={introText} />
						<View style={styles.stats}>
							<CarbonSequestered
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
							{/* <OrganicFertilizerCreated
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/> */}
						</View>
						<Text
							style={{
								fontSize: 12,
								fontWeight: 'bold',
								textAlign: 'center',
								marginHorizontal: 20,
								backgroundColor: reportHeaderBackground,
								color: reportHeaderText,
								borderRadius: '10px',
								padding: 10,
							}}>
							US EPA Carbon Equivalents
						</Text>
						<View style={styles.stats}>
							<SmartphonesCharged
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
							<MilesDriven
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
						</View>
						<View style={styles.stats}>
							<TreesPlanted
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
							<HomesPowered
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
						</View>
						{/* <Text
							style={{
								fontSize: 12,
								fontWeight: 'bold',
								textAlign: 'center',
								marginHorizontal: 20,
								backgroundColor: reportHeaderBackground,
								color: reportHeaderText,
								borderRadius: '10px',
								padding: 10,
							}}>
							ATTRA & USDA Fertilized Equivalents
						</Text>
						<View style={styles.stats}>
							<FarmlandRevitalized
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
							<CornGrownFromFarmland
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
							<MovieTheaterPopcorn
								totalAvoidedFoodWaste={totalAvoidedFoodWaste}
								reportStatisticText={reportStatisticText}
							/>
						</View> */}

						<Text
							style={{
								fontSize: 12,
								textAlign: 'center',
								color: reportFontColor,
								marginHorizontal: 10,
								marginVertical: 20,
							}}>
							{outroText}
						</Text>
					</View>

					{/* Add more sections with data and styling as needed to match the provided layout */}
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default PDFSection;
