import React, { useContext, useEffect, useState } from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getStyles } from '../styles';
import { getFoodWastePerCoverByMonthAndYear } from '../../../../functions/getFoodWastePerCoverByMonthAndYear';

const FWPerCover = () => {
	const theme = useTheme();
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({});
	const styles = getStyles(theme);

	const processChartData = (feedsData) => {
		if (!feedsData) {
			console.error('HOME/COMPONENTS/CHARTS/COMPONENTS/FWPERCOVER: feedsData is undefined');
			return;
		}

		const labels = [
			...new Set(
				Object.values(feedsData)
					.flatMap((locationData) => Object.keys(locationData))
			),
		].sort();

		const datasets = Object.entries(feedsData).map(([locationName, dataByMonth]) => {
			const data = labels.map((label) => dataByMonth[label] || null); // Ensure alignment with labels

			return {
				label: locationName,
				data,
				fill: false,
				borderWidth: 2,
				lineTension: 0.3,
			};
		});

		setChartData({
			labels,
			datasets,
		});
	};

	useEffect(() => {
		async function fetchData() {
			const feedsData = await getFoodWastePerCoverByMonthAndYear(organization._id);
			processChartData(feedsData);
		}
		fetchData();
	}, [organization]);

	const options = {
		responsive: true,
		aspectRatio: 4,
		scales: {
			x: {
				title: {
					display: true,
					text: 'Date',
					font: { size: 14 },
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Food Recycled Per Cover (oz)',
					font: { size: 14 },
				},
			},
		},
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				formatter: (value) => (value ? value.toFixed(1) : ''),
			},
			legend: {
				onClick: (e, legendItem, legend) => {
					const ci = legend.chart;
					const index = legendItem.datasetIndex;

					// Initialize lastClickTime if it doesn't exist
					if (!ci.lastClickTime) {
						ci.lastClickTime = {};
					}

					const currentTime = new Date().getTime();
					const lastClickTime = ci.lastClickTime[index] || 0;
					const doubleClickThreshold = 300;

					// Check if only the clicked dataset is visible
					const isOnlyThisDatasetVisible = () =>
						ci.data.datasets.filter((_, i) => ci.getDatasetMeta(i).hidden !== true).length === 1 &&
						ci.data.datasets[index] === ci.data.datasets[index];

					if (currentTime - lastClickTime <= doubleClickThreshold) {
						// Double-click: isolate or reset all datasets
						if (isOnlyThisDatasetVisible()) {
							ci.data.datasets.forEach((_, i) => (ci.getDatasetMeta(i).hidden = false));
						} else {
							ci.data.datasets.forEach((_, i) => (ci.getDatasetMeta(i).hidden = i !== index));
						}
					} else {
						// Single-click: toggle visibility
						const meta = ci.getDatasetMeta(index);
						meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
					}

					ci.lastClickTime[index] = currentTime;
					ci.update();
				},
			},
		},
	};

	return (
		<Stack direction='row' gap='1rem' sx={{ width: '100%' }}>
			<Paper style={styles.paper}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '2rem',
						alignItems: 'center',
						gap: '1rem',
					}}>
					{chartData && chartData.labels?.length > 0 ? (
						<>
							<Typography variant='p'>
								Double click a location to isolate, single click to hide
							</Typography>
							<Line data={chartData} options={options} />
						</>
					) : (
						<Typography>No data available</Typography>
					)}
				</div>
			</Paper>
		</Stack>
	);
};

export default FWPerCover;
