import React, { useContext } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Grid,
} from '@mui/material';
import { UserContext } from '../../../../context/UserContext';
import { isSiteChampion } from '../../../../utils/isSiteChampion';

const UsersTable = ({ users, classes, selectedUser, setSelectedUser }) => {
	const { user } = useContext(UserContext);

	const shouldShowCodeColumn = isSiteChampion(user) || user?.organization === 'BioGreen360';
	const shouldShowServiceColumn = user?.organization === 'BioGreen360';

	return (
		<Grid container marginTop='1rem'>
			<Grid item xs={12}>
				<div style={classes.usersTable}>
					<TableContainer>
						<Table stickyHeader aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell>First</TableCell>
									<TableCell>Last</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Title</TableCell>
									{shouldShowCodeColumn && <TableCell>Code</TableCell>}
									<TableCell>Department</TableCell>
									<TableCell>Site Champion</TableCell>
									<TableCell>Cover Editor</TableCell>
									<TableCell>Language</TableCell>
									<TableCell>Feeds</TableCell>
									<TableCell>Containers</TableCell>
									<TableCell>Training</TableCell>
									{shouldShowServiceColumn && <TableCell>Service</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map((userData, id) => (
									<TableRow
										key={id}
										onClick={() => {
											setSelectedUser(userData);
										}}
										sx={{
											backgroundColor:
												selectedUser === userData ? '#e7e7e7' : 'transparent',
										}}>
										<TableCell>{userData.FIRST || '-'}</TableCell>
										<TableCell>{userData.LAST || '-'}</TableCell>
										<TableCell>{userData.email || '-'}</TableCell>
										<TableCell>{userData.title || '-'}</TableCell>
										{shouldShowCodeColumn && (
											<TableCell>{userData.CODE || '-'}</TableCell>
										)}
										<TableCell>{userData.userDept || '-'}</TableCell>
										<TableCell>{userData.siteChampion ? '✔' : 'x'}</TableCell>
										<TableCell>{userData.canEditCovers ? '✔' : 'x'}</TableCell>
										<TableCell>{userData.LANGUAGE || '-'}</TableCell>
										<TableCell>{userData.FEEDS ? '✔' : '-'}</TableCell>
										<TableCell>{userData.RESIDUAL ? '✔' : '-'}</TableCell>
										<TableCell>{userData.TRAINING ? '✔' : '-'}</TableCell>
										{shouldShowServiceColumn && (
											<TableCell>{userData.SERVICE ? '✔' : '-'}</TableCell>
										)}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</Grid>
		</Grid>
	);
};

export default UsersTable;
