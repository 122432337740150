import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import FWPerCover from './FWPerCover';
import Covers from './Covers';
import RecycledFoodTrends from './RecycyledFoodTrends';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';

const Individual = () => {
	const { organization } = useContext(OrganizationContext);

	return (
		<Stack direction='column' margin='1rem 1rem 3rem 0' padding='1rem' gap='1rem'>
			<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
				Food Recycled
			</Typography>
			<RecycledFoodTrends />
			<>
				<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
					Cover Counts
				</Typography>
				<Covers />
			</>
			{/* NEED TO FIX BACKEND LOGIC BEFORE PUBLISHING FOOD RECYCLED PER COVER */}
			<>
				<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
					Food Recycled Per Cover
				</Typography>
				<FWPerCover />
			</>
		</Stack>
	);
};

export default Individual;
