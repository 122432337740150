import { useContext, useEffect, useState, useRef } from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getStyles } from '../styles';
import { get30DaysAggregateFeedsTotals } from '../../../../functions/get30DaysAggregateFeedsTotals';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';
import { getTimezoneOffsetInMinutes } from '../../../../../../utils/getTimezoneOffsetInMinutes';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

const RecycledFoodTrends = () => {
	const theme = useTheme();
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({});
	const chartRef = useRef(null);

	const styles = getStyles(theme);

	const processChartData = (feedsData) => {
		if (!feedsData) {
		  console.error('feedsData is undefined');
		  return;
		}
	  
		// Convert date strings to Date objects for proper sorting
		const labels = Object.keys(feedsData).sort((a, b) => new Date(a) - new Date(b));
	  
		// Map the totals to the sorted labels
		const data = labels.map((label) => feedsData[label]);
	  
		setChartData({
		  labels,
		  datasets: [
			{
			  pointRadius: 1,
			  label: 'Aggregate Food Recycled (lbs)',
			  data: data,
			  fill: {
				target: 'origin', 
			  },
			  borderColor: 'rgb(75, 192, 192)',
			  backgroundColor: 'rgba(75, 192, 192, 0.5)',
			},
		  ],
		});
	  };
	  

	useEffect(() => {
		async function fetchData() {
			const timezoneOffset = getTimezoneOffsetInMinutes();
			const feedsData = await get30DaysAggregateFeedsTotals(organization);
			processChartData(feedsData);
		}
		fetchData();
	}, [organization]);

	useEffect(() => {
		if (chartRef.current && chartRef.current.ctx) {
			const ctx = chartRef.current.ctx;
			const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight);
			gradient.addColorStop(0, 'rgba(75, 192, 192, 0.8)');
			gradient.addColorStop(1, 'rgba(75, 192, 192, 0)');

			const chartInstance = chartRef.current;
			chartInstance.data.datasets.forEach((dataset) => {
				dataset.backgroundColor = gradient; // Apply gradient to background color
			});
			chartInstance.update();
		}
	}, [chartData]);

	const options = {
		responsive: true,
		aspectRatio: 4, // Default is 2 (2:1), setting to a higher value will make the chart "shorter"
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				formatter: (value) => value.toFixed(0), // Limit to 1 decimal place
			},
		},
		scales: {
			y: {
				ticks: {
					padding: 20, // Increase this value to push labels further out
					font: {
						size: 12, // Increase this value to enlarge the font size
					},
				},
				beginAtZero: true,
				grid: {
					drawBorder: true,
					drawTicks: false,
				},
			},
			x: {
				ticks: {
					font: {
						size: 12, // Increase this value to enlarge the font size
					},
					padding: 10, // Increase this value to push labels further out
				},
				grid: {
					drawTicks: false,

					drawBorder: false, // Ensures no border lines are drawn at the edges of the x-axis
					// drawOnChartArea: false, // This will remove the grid lines
				},
			},
		},
	};

	return (
		<Stack direction='row' gap='1rem' sx={{ width: '100%' }}>
			<Paper style={styles.paper}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '2rem',
						alignItems: 'center',
						gap: '1rem',
					}}>
					{chartData && chartData.labels?.length > 0 ? (
						<>
							{/* Only resizes properly with this empty typography for some reason... */}
							<Typography variant='p'></Typography>
							<Line ref={chartRef} data={chartData} options={options} />
						</>
					) : (
						<Typography></Typography>
					)}
				</div>
			</Paper>
		</Stack>
	);
};


export default RecycledFoodTrends;
